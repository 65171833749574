import React from 'react';
import { useUser } from '../contexts/UserContext';

function TaskTable({ 
 rows, 
 setRows, 
 calculateProgress, 
 addNewRow, 
 addSubtask, 
 removeRow, 
 updateSubtask,
 updateMainTask, 
 removeSubtask,
 formatDateForDisplay,
 formatDateForInput,
 getOwnerUsername,
 getOwnerId
}) {
 const { userRole } = useUser();
 const isAdmin = userRole === 'ADMIN';

 return (
   <div className="p-4 mt-6 bg-gray-200 rounded-lg task-table">
     <table className="w-full bg-white rounded-lg shadow table-auto">
       <thead>
         <tr className="text-left bg-gray-300">
           <th className="p-2">Objective</th>
           <th className="p-2">Owner</th>
           <th className="p-2">Deadline</th>
           <th className="p-2">Priority</th>
           <th className="p-2">Progress</th>
           {isAdmin && <th className="p-2">Actions</th>}
         </tr>
       </thead>
       <tbody>
         {rows.map((row, index) => (
           <React.Fragment key={row._id || index}>
             <tr className="border-b">
               <td className="p-2">
                 <input
                   type="text"
                   value={row.objective || ''}
                   onChange={(e) => updateMainTask(index, 'objective', e.target.value)}
                   className="w-full p-1 border rounded"
                   placeholder="Objective"
                   disabled={!isAdmin}
                 />
               </td>
               <td className="p-2">
                 <input
                   type="text"
                   value={getOwnerUsername(row.owner)}
                   onChange={(e) => updateMainTask(index, 'owner', {
                     _id: getOwnerId(row.owner),
                     username: e.target.value
                   })}
                   className="w-full p-1 border rounded"
                   placeholder="Owner"
                   disabled={!isAdmin}
                 />
               </td>
               <td className="p-2">
                 <input
                   type="date"
                   value={formatDateForInput(row.deadline)}
                   onChange={(e) => updateMainTask(index, 'deadline', e.target.value)}
                   className="w-full p-1 border rounded"
                   disabled={!isAdmin}
                 />
               </td>
               <td className="p-2">
                 <select
                   value={row.priority}
                   onChange={(e) => updateMainTask(index, 'priority', e.target.value)}
                   className="w-full p-1 border rounded"
                   disabled={!isAdmin}
                 >
                   <option value="High">High</option>
                   <option value="Medium">Medium</option>
                   <option value="Low">Low</option>
                 </select>
               </td>
               <td className="p-2">
                 <div className="w-full h-4 bg-gray-300 rounded-full">
                   <div
                     className="h-4 bg-blue-600 rounded-full"
                     style={{ width: `${calculateProgress(row.subtasks)}%` }}
                   ></div>
                 </div>
                 <p className="text-sm text-gray-700">{calculateProgress(row.subtasks)}%</p>
               </td>
               {isAdmin && (
                 <td className="p-2">
                   <div className="flex flex-col gap-2">
                     <button onClick={() => removeRow(index)} className="px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600">
                       Remove
                     </button>
                     <button onClick={() => addSubtask(index)} className="px-3 py-1 text-white bg-green-500 rounded hover:bg-green-600">
                       Add Subtask
                     </button>
                   </div>
                 </td>
               )}
             </tr>

             {row.subtasks.map((subtask, subIndex) => (
               <tr key={subtask._id || subIndex} className="bg-gray-100 border-b">
                 <td className="p-2 pl-8">
                   <input
                     type="text"
                     value={subtask.objective || ''}
                     onChange={(e) => updateSubtask(index, subIndex, 'objective', e.target.value)}
                     className="w-full p-1 border rounded"
                     placeholder="Subtask Objective"
                     disabled={!isAdmin}
                   />
                 </td>
                 <td className="p-2">
                   <input
                     type="text"
                     value={getOwnerUsername(subtask.owner)}
                     onChange={(e) => updateSubtask(index, subIndex, 'owner', {
                       _id: getOwnerId(subtask.owner),
                       username: e.target.value
                     })}
                     className="w-full p-1 border rounded"
                     placeholder="Subtask Owner"
                     disabled={!isAdmin}
                   />
                 </td>
                 <td className="p-2">
                   <input
                     type="date"
                     value={formatDateForInput(subtask.deadline)}
                     onChange={(e) => updateSubtask(index, subIndex, 'deadline', e.target.value)}
                     className="w-full p-1 border rounded"
                     disabled={!isAdmin}
                   />
                 </td>
                 <td className="p-2">
                   <select
                     value={subtask.priority}
                     onChange={(e) => updateSubtask(index, subIndex, 'priority', e.target.value)}
                     className="w-full p-1 border rounded"
                     disabled={!isAdmin}
                   >
                     <option value="High">High</option>
                     <option value="Medium">Medium</option>
                     <option value="Low">Low</option>
                   </select>
                 </td>
                 <td className="p-2">
                   <select
                     value={subtask.progress || 'Not started'}
                     onChange={(e) => updateSubtask(index, subIndex, 'progress', e.target.value)}
                     className="w-full p-1 border rounded"
                     disabled={!isAdmin}
                   >
                     <option value="Not started">Not started</option>
                     <option value="In progress">In progress</option>
                     <option value="Done">Done</option>
                   </select>
                 </td>
                 {isAdmin && (
                   <td className="p-2">
                     <button onClick={() => removeSubtask(index, subIndex)} className="px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600">
                       Remove
                     </button>
                   </td>
                 )}
               </tr>
             ))}
           </React.Fragment>
         ))}
       </tbody>
     </table>
     
     {isAdmin && (
       <button onClick={addNewRow} className="px-4 py-2 mt-4 text-white bg-blue-500 rounded hover:bg-blue-600">
         Add Row
       </button>
     )}
   </div>
 );
}

export default TaskTable;