// InboxBoard.js

import React, { useState } from 'react';

const InboxBoard = () => {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');

  // Example hardcoded tasks and messages
  const tasks = [
    { id: 1, title: "Complete project report", dueDate: "Nov 15, 2024", priority: "High" },
    { id: 2, title: "Client meeting preparation", dueDate: "Nov 20, 2024", priority: "Medium" },
  ];

  const slackMessages = [
    { id: 1, subject: "System Maintenance", message: "Scheduled maintenance on Nov 18, 2024." },
    { id: 2, subject: "Holiday Reminder", message: "Company holiday on Thanksgiving!" },
  ];

  const holidays = [
    { date: "Dec 25, 2024", message: "Merry Christmas! 🎄 Enjoy the holiday season!" },
  ];

  // Add a new note to the notes list
  const addNote = () => {
    if (newNote.trim() !== '') {
      setNotes([...notes, { id: notes.length + 1, text: newNote }]);
      setNewNote('');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 p-6 text-gray-100">
      <div className="max-w-4xl mx-auto space-y-8">
        <h1 className="text-3xl font-bold text-center">User Inbox</h1>

        {/* Tasks Section */}
        <section className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-4">
          <h2 className="text-xl font-semibold border-b border-gray-700 pb-2">Upcoming Tasks</h2>
          {tasks.map(task => (
            <div key={task.id} className="p-4 bg-gray-700 rounded-md hover:bg-gray-600 transition">
              <h3 className="font-semibold text-lg">{task.title}</h3>
              <p>Due: {task.dueDate}</p>
              <p className={`text-sm font-semibold ${task.priority === 'High' ? 'text-red-500' : task.priority === 'Medium' ? 'text-yellow-500' : 'text-green-500'}`}>
                Priority: {task.priority}
              </p>
            </div>
          ))}
        </section>

        {/* Notes Section */}
        <section className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-4">
          <h2 className="text-xl font-semibold border-b border-gray-700 pb-2">Personal Notes</h2>
          <div className="flex items-center space-x-3">
            <input
              type="text"
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              placeholder="Add a note..."
              className="w-full p-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={addNote}
              className="px-4 py-2 bg-blue-600 rounded-lg text-white font-semibold hover:bg-blue-500 transition"
            >
              Add
            </button>
          </div>
          <div className="space-y-3 mt-4">
            {notes.map(note => (
              <div key={note.id} className="p-3 bg-gray-700 rounded-md">{note.text}</div>
            ))}
          </div>
        </section>

        {/* Slack Messages Section */}
        <section className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-4">
          <h2 className="text-xl font-semibold border-b border-gray-700 pb-2">Slack Messages</h2>
          {slackMessages.map(msg => (
            <div key={msg.id} className="p-4 bg-gray-700 rounded-md hover:bg-gray-600 transition">
              <h3 className="font-semibold">{msg.subject}</h3>
              <p>{msg.message}</p>
            </div>
          ))}
        </section>

        {/* Holiday Greetings Section */}
        <section className="bg-blue-700 p-6 rounded-lg shadow-lg space-y-4">
          <h2 className="text-xl font-semibold border-b border-blue-600 pb-2">Holiday Greetings</h2>
          {holidays.map(holiday => (
            <div key={holiday.date} className="p-4 bg-blue-600 rounded-md">
              <h3 className="font-semibold">{holiday.date}</h3>
              <p>{holiday.message}</p>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default InboxBoard;
