import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';


function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  // Login
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://pureokr-backend.onrender.com/login', { username, password });
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', username); // save username to localStorage
        localStorage.setItem('userRole', response.data.role);
        navigate('/');  // redirect to ...
        window.location.reload();
      } else {
        alert('Login failed. Token not received.');
      }
    } catch (err) {
      console.log(err.response.data);
      alert('Login failed. '+ err.response.data.message);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
    {/* <Logo /> */}
      <form onSubmit={handleSubmit} className="p-6 bg-white rounded shadow-md w-80">
        <h2 className="mb-4 text-lg font-bold">Login</h2>
        <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} className="block w-full p-2 mb-4 border" />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} className="block w-full p-2 mb-4 border" />
        <button type="submit" className="block w-full px-4 py-2 mb-4 text-white bg-black rounded">Login</button>
        <div className="text-center">
          <Link to="/register" className="text-black">Don't have an account? Register</Link>
        </div>
      </form>
    </div>
  );
}

export default Login;
