import React from 'react';

const MyReports = () => {
  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">My Reports</h2>
      <p>Show all OKRs that you as Direct Reporter.</p>
    </div>
  );
};

export default MyReports;
