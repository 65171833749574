// TeamMessageBoard.js

import React, { useState } from 'react';
import axios from 'axios';

const TeamMessageBoard = () => {
  const [formData, setFormData] = useState({
    subject: '',
    message: '',
    urgency: '',
    followUp: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const message = `
      *${formData.subject || 'Announcement'}*\n
      *Urgency*: ${formData.urgency}\n
      *Message*: ${formData.message}\n
      *Follow-Up Needed*: ${formData.followUp ? 'Yes' : 'No'}
    `;

    try {
      await axios.post(
        'https://pureokr-backend.onrender.com/api/slack/send-message',
        { text: message }
      );
      alert('Announcement sent successfully to Slack!');
      
      setFormData({
        subject: '',
        message: '',
        urgency: '',
        followUp: false,
      });
    } catch (error) {
      alert('Error sending announcement to Slack.');
      console.error(error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen p-6 bg-gradient-to-br from-gray-900 via-gray-800 to-black">
      <div className="w-full max-w-md p-8 text-gray-100 transition duration-500 transform bg-gray-900 rounded-lg shadow-lg hover:scale-105 hover:shadow-xl">
        <h2 className="mb-6 text-2xl font-extrabold tracking-wide text-center text-gray-200">Team Announcement Board</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <input 
            type="text" 
            name="subject" 
            placeholder="Subject" 
            value={formData.subject} 
            onChange={handleChange} 
            className="w-full p-4 text-gray-100 placeholder-gray-500 transition duration-300 bg-gray-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-gray-700"
          />
          <textarea 
            name="message" 
            placeholder="Announcement Message" 
            value={formData.message} 
            onChange={handleChange} 
            required 
            className="w-full p-4 bg-gray-800 rounded-lg text-gray-100 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-gray-700 transition duration-300 min-h-[120px] resize-none"
          />
          <select 
            name="urgency" 
            value={formData.urgency} 
            onChange={handleChange} 
            className="w-full p-4 text-gray-100 transition duration-300 bg-gray-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-gray-700"
          >
            <option value="" className="text-gray-500">Select Urgency Level</option>
            <option value="Low">Low</option>
            <option value="Medium">Medium</option>
            <option value="High">High</option>
          </select>
          <label className="flex items-center space-x-3 text-gray-200">
            <input 
              type="checkbox" 
              name="followUp" 
              checked={formData.followUp} 
              onChange={handleChange} 
              className="w-5 h-5 text-blue-500 transition duration-300 rounded focus:ring-blue-500"
            />
            <span>Follow-up Needed</span>
          </label>
          <button 
            type="submit" 
            className="w-full py-3 font-bold tracking-wide text-white transition duration-300 bg-blue-600 rounded-lg shadow-md hover:bg-blue-500 hover:shadow-lg focus:outline-none focus:ring-4 focus:ring-blue-700"
          >
            Send Announcement
          </button>
        </form>
      </div>
    </div>
  );
};

export default TeamMessageBoard;
