import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';


function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('USER'); // New state for role selection
  const navigate = useNavigate();

  // Register
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://pureokr-backend.onrender.com/register', { username, password, role });
      alert('Registration successful. Please login.');
      navigate('/login'); // redirect to login page
    } catch (err) {
      console.log(err.response.data.message);
      alert(err.response.data.message + ", Registration failed.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">

      <form onSubmit={handleSubmit} className="p-6 bg-white rounded shadow-md w-80">
        <h2 className="mb-4 text-lg font-bold">Register</h2>
        <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} className="block w-full p-2 mb-4 border" />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} className="block w-full p-2 mb-4 border" />
        <button type="submit" className="block w-full px-4 py-2 mb-4 text-white bg-black rounded">Register</button>
        <div className="text-center">
          <Link to="/login" className="text-black">Already have an account? Login</Link>
        </div>
      </form>
    </div>
  );
}

export default Register;
