import React, { useState, useEffect } from 'react';
import TasksPage from '../components/TasksPage';
import { fetchTeams, normalizeObjective } from '../services/api';


function MyTeams() {

  const [rows, setRows] = useState([]);
  const [error, setError] = useState(null);


  const loadObjectives = async () => {
    try {
      const objectives = await fetchTeams();
      const normalizedObjectives = objectives.map(normalizeObjective);      
      setRows(normalizedObjectives);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    loadObjectives();
  }, []);

  const teamAOKRs = [
    {
      objective: 'Improve Product Quality',
      owner: 'Alice',
      deadline: '2025-12-31',
      priority: 'High',
      subtasks: [
        { objective: 'Reduce bugs by 50%', owner: 'Bob', deadline: '2025-10-01', priority: 'High', progress: 'In progress' },
        { objective: 'Implement automated testing', owner: 'Charlie', deadline: '2025-11-15', priority: 'Medium', progress: 'Not started' },
      ],
    },
  ];

  const teamBOKRs = [
    {
      objective: 'Increase Market Share',
      owner: 'Dana',
      deadline: '2025-12-15',
      priority: 'Medium',
      subtasks: [
        { objective: 'Expand to new regions', owner: 'Eve', deadline: '2025-09-30', priority: 'High', progress: 'In progress' },
        { objective: 'Increase customer base by 20%', owner: 'Frank', deadline: '2025-11-30', priority: 'Medium', progress: 'Not started' },
      ],
    },
  ];

  return (
    <div className="flex flex-col gap-8 p-8 my-teams-page">
      <h1 className="mb-6 text-3xl font-bold">My Teams' OKRs</h1>
      
      {
        rows.map((row, index) => (          
          <TasksPage key={index} teamId={row._id} title={row.teamName} initialData={row.okrs} />
        ))
      }
    </div>
  );
}

export default MyTeams;
