import React from 'react';
import TasksPage from '../components/TasksPage';

function MyWork() {
  return (
    <div className="my-work-page">
      <h1 className="mb-6 text-3xl font-bold">My Work</h1>
      <TasksPage initialData={[]} />
    </div>
  );
}

export default MyWork;
