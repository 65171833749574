// components/TasksPage.js

import React, { useState, useEffect } from 'react';
import TaskTable from './TaskTable';
import { useUser } from '../contexts/UserContext';
import { 
    updateTeamOKR, 
    updateTeamSubtask, 
    deleteTeamOKR, 
    addTeamOKR, 
    addTeamSubtask, 
    deleteTeamSubtask 
} from '../services/api';

function TasksPage({ title, teamId, initialData = [] }) {
    console.log('Initial Data:', initialData);
    const [rows, setRows] = useState(initialData);
    const { userRole } = useUser();
    const isAdmin = userRole === 'ADMIN';

    useEffect(() => {
        console.log('Setting rows with:', initialData);
        setRows(initialData);
    }, [initialData]);

    const calculateProgress = (subtasks) => {
        const totalSubtasks = subtasks.length;
        const doneSubtasks = subtasks.filter((subtask) => subtask.progress === 'Done').length;
        return totalSubtasks > 0 ? ((doneSubtasks / totalSubtasks) * 100).toFixed(0) : 0;
    };

    const formatDateForInput = (dateString) => {
        if (!dateString) return '';
        try {
            const date = new Date(dateString);
            return date.toISOString().split('T')[0];
        } catch (error) {
            console.error('Date formatting error:', error);
            return '';
        }
    };

    const addNewRow = async () => {
        try {
            const today = new Date().toISOString().split('T')[0];
            const newOKR = {
                objective: '',
                owner: {
                    _id: '',
                    username: ''
                },
                deadline: today,
                priority: 'Medium',
                subtasks: []
            };
            
            const response = await addTeamOKR(teamId, newOKR);
            setRows([...rows, response]);
        } catch (error) {
            console.error('Failed to add new OKR:', error);
        }
    };

    const addSubtask = async (taskIndex) => {
        try {
            const today = new Date().toISOString().split('T')[0];
            const newSubtask = {
                objective: '',
                owner: {
                    _id: '',
                    username: ''
                },
                deadline: today,
                priority: 'Medium',
                progress: 'Not started'
            };

            const response = await addTeamSubtask(teamId, taskIndex, newSubtask);
            const newRows = [...rows];
            newRows[taskIndex].subtasks.push(response);
            setRows(newRows);
        } catch (error) {
            console.error('Failed to add subtask:', error);
        }
    };

    const removeRow = async (indexToRemove) => {
        try {
            await deleteTeamOKR(teamId, rows[indexToRemove]._id);
            setRows(rows.filter((_, index) => index !== indexToRemove));
        } catch (error) {
            console.error('Failed to remove OKR:', error);
        }
    };

    const removeSubtask = async (taskIndex, subtaskIndex) => {
        try {
            await deleteTeamSubtask(teamId, rows[taskIndex]._id, rows[taskIndex].subtasks[subtaskIndex]._id);
            const newRows = [...rows];
            newRows[taskIndex].subtasks = newRows[taskIndex].subtasks.filter((_, index) => index !== subtaskIndex);
            setRows(newRows);
        } catch (error) {
            console.error('Failed to remove subtask:', error);
        }
    };

    const updateMainTask = async (taskIndex, field, value) => {
        try {
            const updatedData = { [field]: value };
            await updateTeamOKR(teamId, rows[taskIndex]._id, updatedData);
            
            const newRows = [...rows];
            if (field === 'owner') {
                newRows[taskIndex].owner = value;
            } else if (field === 'deadline') {
                newRows[taskIndex].deadline = value;
            } else {
                newRows[taskIndex][field] = value;
            }
            setRows(newRows);
        } catch (error) {
            console.error('Failed to update main task:', error);
        }
    };

    const updateSubtask = async (taskIndex, subtaskIndex, field, value) => {
        try {
            const updatedData = { [field]: value };
            await updateTeamSubtask(
                teamId, 
                rows[taskIndex]._id, 
                rows[taskIndex].subtasks[subtaskIndex]._id, 
                updatedData
            );

            const newRows = [...rows];
            if (field === 'owner') {
                newRows[taskIndex].subtasks[subtaskIndex].owner = value;
            } else if (field === 'deadline') {
                newRows[taskIndex].subtasks[subtaskIndex].deadline = value;
            } else {
                newRows[taskIndex].subtasks[subtaskIndex][field] = value;
            }
            setRows(newRows);
        } catch (error) {
            console.error('Failed to update subtask:', error);
        }
    };

    const formatDateForDisplay = (dateString) => {
        if (!dateString) return '';
        try {
            const date = new Date(dateString);
            return date.toLocaleDateString();
        } catch (error) {
            console.error('Date display formatting error:', error);
            return '';
        }
    };

    const getOwnerUsername = (owner) => {
        console.log('Getting username for owner:', owner);
        if (!owner) return '';
        return owner.username || '';
    };

    const getOwnerId = (owner) => {
        console.log('Getting ID for owner:', owner);
        if (!owner) return '';
        return owner._id || '';
    };

    return (
        <div className="flex justify-center App">
            <main className="w-full p-8 max-w-screen-2xl">
                <section className="w-full max-w-full p-4 -mx-8 bg-gray-100 rounded-lg shadow">
                    <h1 className="mb-4 text-3xl font-bold">{title}</h1>
                    <TaskTable
                        rows={rows}
                        setRows={setRows}
                        calculateProgress={calculateProgress}
                        addNewRow={isAdmin ? addNewRow : null}
                        addSubtask={isAdmin ? addSubtask : null}
                        removeRow={isAdmin ? removeRow : null}
                        updateSubtask={updateSubtask}
                        updateMainTask={updateMainTask}
                        removeSubtask={isAdmin ? removeSubtask : null}
                        formatDateForDisplay={formatDateForDisplay}
                        formatDateForInput={formatDateForInput}
                        getOwnerUsername={getOwnerUsername}
                        getOwnerId={getOwnerId}
                    />
                </section>
            </main>
        </div>
    );
}

export default TasksPage;