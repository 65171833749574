import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import MyWork from './pages/MyWork';
import MyReports from './pages/MyReports';
import MyTeams from './pages/MyTeams';
import Login from './pages/Login';
import Register from './pages/Register';
import MessageBoard from './pages/MessageBoard';
import Inbox from './pages/inbox';
import { UserProvider } from './contexts/UserContext';

import {
  Sidebar,
  SidebarBody,
  SidebarHeader,
  SidebarSection,
  SidebarItem,
  SidebarLabel,
} from './components/sidebar';
import { SidebarLayout } from './components/sidebar-layout';
import {
  HomeIcon,
  InboxIcon,
  MagnifyingGlassIcon,
  Square2StackIcon,
  TicketIcon,
  Cog6ToothIcon,
  ChatBubbleBottomCenterIcon,
  ArrowRightStartOnRectangleIcon
} from '@heroicons/react/24/solid';
import { Logo } from './components/logo';

function SidebarWithLogout() {
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem('token') !== null;
  
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('userRole');
    navigate('/login');
    window.location.href = '/login';
  };

  return (
    <Sidebar>
      <SidebarHeader>
        <Logo />
      </SidebarHeader>
      <SidebarBody>
        <SidebarSection>
          <SidebarItem as={Link} to="/search">
            <MagnifyingGlassIcon />
            <SidebarLabel>Search</SidebarLabel>
          </SidebarItem>
          <SidebarItem as={Link} to="/inbox">
            <InboxIcon />
            <SidebarLabel>Inbox</SidebarLabel>
          </SidebarItem>
          {isAuthenticated && (
            <SidebarItem onClick={handleLogout} className="cursor-pointer">
              <ArrowRightStartOnRectangleIcon />
              <SidebarLabel>Logout</SidebarLabel>
            </SidebarItem>
          )}
        </SidebarSection>
        <SidebarSection>
          <SidebarItem as={Link} to="/">
            <HomeIcon />
            <SidebarLabel>Home</SidebarLabel>
          </SidebarItem>
          <SidebarItem as={Link} to="/my-work">
            <Square2StackIcon />
            <SidebarLabel>My Work</SidebarLabel>
          </SidebarItem>
          <SidebarItem as={Link} to="/my-reports">
            <TicketIcon />
            <SidebarLabel>My Reports</SidebarLabel>
          </SidebarItem>
          <SidebarItem as={Link} to="/my-teams">
            <Cog6ToothIcon />
            <SidebarLabel>My Teams</SidebarLabel>
          </SidebarItem>
          <SidebarItem as={Link} to="/message-board">
            <ChatBubbleBottomCenterIcon />
            <SidebarLabel>Message Board</SidebarLabel>
          </SidebarItem>
        </SidebarSection>
      </SidebarBody>
    </Sidebar>
  );
}

function App() {
  const isAuthenticated = localStorage.getItem('token') !== null;

  return (
    <UserProvider>
      <Router>
        <SidebarLayout
          sidebar={<SidebarWithLogout />}
        >
          <Routes>
            <Route path="/" element={isAuthenticated ? <Home /> : <Navigate to="/login" />} />
            <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <Login />} />
            <Route path="/register" element={isAuthenticated ? <Navigate to="/" /> : <Register />} />
            <Route path="/my-work" element={isAuthenticated ? <MyWork /> : <Navigate to="/login" />} />
            <Route path="/my-reports" element={isAuthenticated ? <MyReports /> : <Navigate to="/login" />} />
            <Route path="/my-teams" element={isAuthenticated ? <MyTeams /> : <Navigate to="/login" />} />
            <Route path="/message-board" element={isAuthenticated ? <MessageBoard /> : <Navigate to="/login" />} />
            <Route path="/inbox" element={isAuthenticated ? <Inbox /> : <Navigate to="/login" />} />
          </Routes>
        </SidebarLayout>
      </Router>
    </UserProvider>
  );
}

export default App;